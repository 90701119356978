import React, { useState } from 'react';
import Modal from 'react-modal';
import OrdenForm from './OrdenForm';

Modal.setAppElement('#root');

const BandejaOrdenes = ({ ordenes, onRowClick, guardarOrden, modoEdicion, orden, proveedores }) => {
  const itemsPerPageOptions = [10, 25, 50, 100];
  const [filteredOrdenes, setFilteredOrdenes] = useState(ordenes);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);  // Para el menú de filtro
  const [showOptions, setShowOptions] = useState(false);    // Para los tres puntos de opciones
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredOrdenes.length / itemsPerPage);

  const paginatedOrdenes = filteredOrdenes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = ordenes.filter((orden) => {
      const formattedImporte = parseFloat(orden?.total)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return (
        orden.nombreProveedor.toLowerCase().includes(searchTerm.toLowerCase()) ||
        orden.asunto.toLowerCase().includes(searchTerm.toLowerCase()) ||
        orden.fechaOrden.includes(searchTerm) ||
        orden.estado.toLowerCase().includes(searchTerm) ||
        orden.numeroOrden.toString().padStart(4, '0').includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredOrdenes(filtered);
    setCurrentPage(1);
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  };

  const openFormulario = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="ordenes-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input 
            type="text" 
            className="bandeja-search-input" 
            placeholder="Buscar..."
            onChange={handleSearch} 
          />
        </div>
      </div>

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <p>Todas las órdenes de compra</p>
          <i className="bi bi-caret-down-fill"></i>
          {showDropdown && (
            <div className="dropdown-menu">
              <p>Órdenes enviadas</p>
              <p>Órdenes pendientes</p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: 'relative' }}>
          <button className="boton-accion" onClick={openFormulario}>+ Nuevo</button>
          <i className="bi bi-three-dots-vertical" onClick={toggleOptions}></i>
          {showOptions && (
            <div className="dropdown-options">
              <p>Ordenar</p>
              <p>Importar</p>
              <p>Exportar</p>
            </div>
          )}
        </div>
      </div>

      {paginatedOrdenes.length > 0 ? (
        paginatedOrdenes.map(orden => (
          <div key={orden.id} className="mensaje-cotizacion" onClick={() => onRowClick(orden.id)}>
            <div className="info-container">
              <input type="checkbox" />
              <div className="info-column">
                <p><span className="cliente-texto">PROVEEDOR:</span> <span className="cliente-nombre">{orden.nombreProveedor}</span></p>
                <div className="fecha-referencia">
                  <p>No. Orden: {orden.numeroOrden.toString().padStart(4, '0')}</p>
                  <span className="punto">•</span>
                  <p>Fecha: {formatDate(new Date(orden.fechaOrden))}</p>
                </div>
              </div>
              <div className="importe-column">
                <p>Importe: ${parseFloat(orden?.total)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p style={{ position: "relative", right: "25%" }}>No existe ninguna orden que coincida con esta búsqueda.</p>
      )}

      <div className="paginador">
        <div className="paginador-opciones">
          <div className="paginador-config">
            <div className="paginador-select-wrapper">
              <i className="bi bi-gear-fill" style={{ marginRight: '10px' }}></i>
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} por página
                  </option>
                ))}
              </select>
            </div>
            <button onClick={() => handlePageChange("prev")} disabled={currentPage === 1}>
              {"<"}
            </button>
            <span>
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, filteredOrdenes.length)}
            </span>
            <button onClick={() => handlePageChange("next")} disabled={currentPage === totalPages}>
              {">"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Orden"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            borderRadius: '0',
            padding: '20px',
            overflow: 'auto',
            fontFamily: 'Roboto, sans-serif',
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">x</button>

        <OrdenForm
          proveedores={proveedores}
          guardarOrden={guardarOrden}
          orden={orden}
        />
      </Modal>
    </div>
  );
};

export default BandejaOrdenes;
