import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import Notificaciones from './Notificaciones';
import FullScreen from './FullScreen';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const Nav = ({ proximasAVencer, proximosEventos, handleSearch }) => {
  const { user, logout } = useAuth();
  const displayNameParts = user?.displayName?.split(' ') || [];
  const firstName = displayNameParts[0] || 'Invitado';
  const lastName = displayNameParts.slice(1).join(' ') || '';
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || '');
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false); // Estado para controlar si el widget de búsqueda está abierto
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserRole = useCallback(async () => {
    try {
      if (user) {
        const userQuery = query(collection(db, 'usuarios'), where('email', '==', user.email));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserRole(userData.rol);
          localStorage.setItem('userRole', userData.rol); // Guardar el rol en localStorage
        } else {
          setUserRole('Rol no encontrado');
        }
      }
    } catch (error) {
      console.error('Error al obtener el rol del usuario:', error);
      setUserRole('Error al cargar rol');
    }
  }, [user]);

  useEffect(() => {
    if (user && !localStorage.getItem('userRole')) {
      fetchUserRole();
    }
  }, [fetchUserRole, user]);

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleSearchWidgetClick = () => {
    setSearchOpen(!searchOpen); // Cambia entre abierto y cerrado al hacer clic
  };

  const handleLogout = async () => {
    await logout();
    localStorage.removeItem('userRole'); // Eliminar el rol de localStorage al cerrar sesión
    navigate('/login');
  };

  // Mapeo de rutas a títulos
  const pageTitles = {
    '/inicio': 'Dashboard',
    '/tareas': 'Tareas',
    '/calendario': 'Calendario',
    '/cotizacion': 'Cotizaciones',
    '/cotizacion-form': 'Cotizaciones',
    '/remisiones': 'Lista de remisiones',
    '/orden': 'Órdenes de Compra',
    '/articulos-venta': 'Artículos de venta',
    '/articulos-compra': 'Artículos de compra',
    '/todos': 'Contactos',
    '/clientes': 'Clientes',
    '/proveedores': 'Proveedores',
    '/usuarios': 'Usuarios'
  };

  const currentTitle = pageTitles[location.pathname] || 'Dashboard';

  return (
    <div style={{ position: 'relative', padding: '5px', boxShadow: '0 1px 5px rgba(0,0,0,0.1)', backgroundColor: '#FFF' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
        {/* Move title slightly to the left */}
        <div style={{ marginLeft: '10px', flex: 1 }}>
          <h3 style={{
            fontWeight: 'bold',
            margin: 0,
            textAlign: "center",
            fontFamily: 'Montserrat, sans-serif',
            letterSpacing: '0.5px',
            color: '#333'
          }}>
            {currentTitle}
          </h3>
        </div>
        {/* Search bar container */}
        <div style={{ position: 'relative', marginRight: '20px' }}>
          <div className={`search-widget ${searchOpen}`} onClick={handleSearchWidgetClick}>
            <i className="bi bi-search"></i>
          </div>
          <div className={`search-input-wrapper ${searchOpen ? 'open' : ''}`}>
            <SearchBar handleSearch={handleSearch} />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginRight: '20px' }}>
          <Notificaciones proximasAVencer={proximasAVencer} proximosEventos={proximosEventos} />
          <i className="bi bi-moon" style={{ cursor: 'pointer', fontSize: '1.5rem' }}></i>
          <FullScreen />
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f7f9f9', width: '200px', padding: '5px', borderRadius: '5px' }}>
              <img src={user?.avatarUrl || '/img/avatar.jpg'} alt="Avatar" style={{ width: '45px', height: '45px', borderRadius: '50%' }} />
              <div style={{ margin: '0 auto' }}>
                <div style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'center' }}>{firstName} {lastName}</div>
                <div style={{ fontWeight: 'normal', fontSize: '14px', textAlign: 'center' }}>{userRole}</div>
              </div>
              <i className="bi bi-caret-down-fill" onClick={handleClick} style={{ fontSize: '1rem', cursor: 'pointer' }}></i>
            </div>
            {menuVisible && (
              <div
                style={{
                  position: 'absolute',
                  top: '60px',
                  right: '0',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  zIndex: 1
                }}
              >
              <div onClick={handleLogout} className="logout-container">
                <span className="logout-icon">
                  <i className="bi bi-box-arrow-right"></i> {/* Icono de Bootstrap */}
                </span>
                <span className="logout-text">Cerrar sesión</span>
              </div>
            </div>         
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;