import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

const Notificaciones = ({ proximasAVencer, proximosEventos }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [unseenCount, setUnseenCount] = useState(0);
  const [seenNotifications, setSeenNotifications] = useState(
    getSeenNotifications()
  );
  const [deletedNotifications, setDeletedNotifications] = useState(
    getDeletedNotifications()
  );
  const [filter, setFilter] = useState("Todas");
  const [menuOpen, setMenuOpen] = useState(null);
  const [mainMenuOpen, setMainMenuOpen] = useState(false); // Nuevo estado
  const [localProximasAVencer, setLocalProximasAVencer] = useState([]);
  const [localProximosEventos, setLocalProximosEventos] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);

  // Funciones para manejar las notificaciones eliminadas
  function getDeletedNotifications() {
    const deleted = localStorage.getItem("deletedNotifications");
    return deleted ? JSON.parse(deleted) : [];
  }

  const setDeletedNotificationsInStorage = (deleted) => {
    localStorage.setItem("deletedNotifications", JSON.stringify(deleted));
  };

  // Inicializar las notificaciones locales filtrando las eliminadas
  useEffect(() => {
    const filteredProximasAVencer = proximasAVencer.filter(
      (item) => !deletedNotifications.includes(item.id)
    );
    setLocalProximasAVencer(filteredProximasAVencer);
  }, [proximasAVencer, deletedNotifications]);

  useEffect(() => {
    const filteredProximosEventos = proximosEventos.filter(
      (evento) => !deletedNotifications.includes(evento.id)
    );
    setLocalProximosEventos(filteredProximosEventos);
  }, [proximosEventos, deletedNotifications]);

  const totalNotifications =
    localProximasAVencer.length + localProximosEventos.length;

  function getSeenNotifications() {
    const seen = localStorage.getItem("seenNotifications");
    return seen ? JSON.parse(seen) : [];
  }

  const setSeenNotificationsInStorage = (seen) => {
    localStorage.setItem("seenNotifications", JSON.stringify(seen));
  };

  const calculateUnseenCount = useCallback(() => {
    const unseenProximasAVencer = localProximasAVencer.filter(
      (item) => !seenNotifications.includes(item.id)
    ).length;
    const unseenProximosEventos = localProximosEventos.filter(
      (evento) => !seenNotifications.includes(evento.id)
    ).length;

    const totalUnseen = unseenProximasAVencer + unseenProximosEventos;
    console.log("Unseen ProximasAVencer:", unseenProximasAVencer);
    console.log("Unseen ProximosEventos:", unseenProximosEventos);
    console.log("Total Unseen:", totalUnseen);

    setUnseenCount(totalUnseen);
  }, [localProximasAVencer, localProximosEventos, seenNotifications]);

  useEffect(() => {
    calculateUnseenCount();
  }, [calculateUnseenCount]);

  const handleNotificationClick = () => {
    setShowNotifications((prev) => !prev);
    // Eliminada la lógica que marcaba todas las notificaciones como leídas
  };

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
  };

  const handleMenuToggle = (id) => {
    if (menuOpen === id) {
      setMenuOpen(null);
    } else {
      setMenuOpen(id);
    }
  };

  const handleMarkAsRead = (id) => {
    if (!seenNotifications.includes(id)) {
      const newSeen = [...seenNotifications, id];
      setSeenNotifications(newSeen);
      setSeenNotificationsInStorage(newSeen);
      setUnseenCount((prev) => prev - 1); // Reducir el conteo de no leídas
    }
    setMenuOpen(null);
  };

  const handleDelete = (id, type) => {
    if (type === "cotizacion") {
      setLocalProximasAVencer((prev) => prev.filter((item) => item.id !== id));
    } else if (type === "evento") {
      setLocalProximosEventos((prev) =>
        prev.filter((evento) => evento.id !== id)
      );
    }
    setMenuOpen(null);

    // Agregar el ID a las notificaciones eliminadas
    if (!deletedNotifications.includes(id)) {
      const newDeleted = [...deletedNotifications, id];
      setDeletedNotifications(newDeleted);
      setDeletedNotificationsInStorage(newDeleted);
    }

    // Eliminar de seenNotifications si ya estaba marcado como leído
    if (seenNotifications.includes(id)) {
      const newSeen = seenNotifications.filter((seenId) => seenId !== id);
      setSeenNotifications(newSeen);
      setSeenNotificationsInStorage(newSeen);
      // No se necesita actualizar unseenCount aquí ya que la notificación ya estaba leída
    }
  };

  const handleMarkAllAsRead = () => {
    const allIds = [
      ...localProximasAVencer.map((item) => item.id),
      ...localProximosEventos.map((evento) => evento.id),
    ];

    const newSeen = Array.from(new Set([...seenNotifications, ...allIds]));
    setSeenNotifications(newSeen);
    setSeenNotificationsInStorage(newSeen);
    setUnseenCount(0); // Ya que todas están marcadas como leídas
    setMainMenuOpen(false); // Cierra el menú principal
  };

  const filteredProximasAVencer =
    filter === "No leídas"
      ? localProximasAVencer.filter(
          (item) => !seenNotifications.includes(item.id)
        )
      : localProximasAVencer;

  const filteredProximosEventos =
    filter === "No leídas"
      ? localProximosEventos.filter(
          (evento) => !seenNotifications.includes(evento.id)
        )
      : localProximosEventos;

  return (
    <div
      className="notificaciones-container"
      style={{ position: "relative", display: "inline-block" }}
    >
      <div
        className="notification-icon-container"
        style={{ position: "relative" }}
      >
        <i
          className="bi bi-bell notification-icon"
          onClick={handleNotificationClick}
          style={{ cursor: "pointer", fontSize: "24px" }}
        ></i>
        {unseenCount > 0 && (
          <div className="notification-badge">{unseenCount}</div>
        )}
      </div>
      {showNotifications && (
        <div className="notifications-dropdown">
          <ul
            className="notifications-list"
            style={{ listStyle: "none", padding: "10px", margin: "0" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    textAlign: "left",
                    color: "#333",
                    fontWeight: "600",
                    fontFamily: "Arial",
                  }}
                >
                  Notificaciones
                </h4>
                <div style={{ position: "relative" }}>
                  <i
                    className="bi bi-three-dots"
                    onClick={() => setMainMenuOpen((prev) => !prev)}
                    style={{ cursor: "pointer" }}
                  ></i>
                  {mainMenuOpen && (
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "25px",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                        zIndex: 1001,
                        width: "160px",
                      }}
                    >
                      <button
                        onClick={handleMarkAllAsRead}
                        onMouseEnter={() => setHoveredButton("markAllAsRead")}
                        onMouseLeave={() => setHoveredButton(null)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor:
                            hoveredButton === "markAllAsRead"
                              ? "#f0f0f0"
                              : "transparent",
                          border: "none",
                          textAlign: "left",
                          cursor: "pointer",
                          fontSize: "14px",
                          color: "#333",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i
                          className="bi bi-check2"
                          style={{
                            marginRight: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color:
                              hoveredButton === "markAllAsRead"
                                ? "#000"
                                : "#333",
                          }}
                        ></i>
                        Marcar como leídos
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* Botones de filtro */}
              <div style={{ display: "flex", marginTop: "-10px" }}>
                <button
                  onClick={() => handleFilterChange("Todas")}
                  style={{
                    backgroundColor:
                      filter === "Todas" ? "#E3F2FD" : "transparent",
                    border: filter === "Todas" ? "none" : "1px solid #ddd",
                    padding: "5px 15px",
                    borderRadius: "20px",
                    color: filter === "Todas" ? "#1E88E5" : "#828282",
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Todas
                </button>
                <button
                  onClick={() => handleFilterChange("No leídas")}
                  style={{
                    backgroundColor:
                      filter === "No leídas" ? "#E3F2FD" : "transparent",
                    border: filter === "No leídas" ? "none" : "1px solid #ddd",
                    padding: "5px 15px",
                    borderRadius: "20px",
                    color: filter === "No leídas" ? "#1E88E5" : "#828282",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  No leídas
                </button>
              </div>
            </div>

            {/* Cotizaciones */}
            {filteredProximasAVencer.length > 0 && (
              <>
                <h4
                  className="notification-header"
                  style={{
                    marginTop: "15px",
                    marginBottom: "10px",
                    color: "#555",
                  }}
                >
                  Cotizaciones:
                </h4>
                {filteredProximasAVencer.map((item) => (
                  <li
                    key={item.id}
                    className="notification-item"
                    style={{
                      position: "relative",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <strong
                      className="notification-title"
                      style={{
                        display: "block",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      Cotización #
                      {item.numeroCotizacion?.toString().padStart(4, "0")}
                    </strong>
                    <span className="notification-date">
                      Vence el{" "}
                      {moment(item.fechaVencimiento).format("DD/MM/YYYY")}
                    </span>
                    {/* Círculo azul si está no leída */}
                    {!seenNotifications.includes(item.id) && (
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "15px",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#007bff",
                          borderRadius: "50%",
                        }}
                      ></div>
                    )}
                    {/* Icono de tres puntos */}
                    <i
                      className="bi bi-three-dots"
                      style={{
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginTop: "-15px",
                      }}
                      onClick={() => handleMenuToggle(item.id)}
                    ></i>

                    {/* Menú desplegable */}
                    {menuOpen === item.id && (
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "25px",
                          backgroundColor: "#fff",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                          zIndex: 1001,
                          width: "160px",
                        }}
                      >
                        {/* Botón Marcar como leído */}
                        <button
                          onClick={() => handleMarkAsRead(item.id)}
                          onMouseEnter={() => setHoveredButton("markAsRead")}
                          onMouseLeave={() => setHoveredButton(null)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            backgroundColor:
                              hoveredButton === "markAsRead"
                                ? "#f0f0f0"
                                : "transparent",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "#333",
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i
                            className="bi bi-check2"
                            style={{
                              marginRight: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              color:
                                hoveredButton === "markAsRead"
                                  ? "#000"
                                  : "#333",
                            }}
                          ></i>
                          Marcar como leído
                        </button>

                        {/* Botón Eliminar */}
                        <button
                          onClick={() => handleDelete(item.id, "cotizacion")}
                          onMouseEnter={() => setHoveredButton("delete")}
                          onMouseLeave={() => setHoveredButton(null)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            backgroundColor:
                              hoveredButton === "delete"
                                ? "#f0f0f0"
                                : "transparent",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "#d9534f",
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{
                              marginRight: "5px",
                              fontSize: "16px",
                              color:
                                hoveredButton === "delete"
                                  ? "#b52b27"
                                  : "#d9534f",
                            }}
                          ></i>
                          Eliminar
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </>
            )}

            {/* Próximos Eventos */}
            {filteredProximosEventos.length > 0 && (
              <>
                <h4
                  className="notification-header"
                  style={{
                    marginTop: "15px",
                    marginBottom: "10px",
                    color: "#555",
                  }}
                >
                  Próximos Eventos:
                </h4>
                {filteredProximosEventos.map((evento) => (
                  <li
                    key={evento.id}
                    className="notification-item"
                    style={{
                      position: "relative",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <strong
                      className="notification-title"
                      style={{
                        display: "block",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      {evento.title}
                    </strong>
                    <span className="notification-date">
                      Evento el {moment(evento.to).format("DD/MM/YYYY")}
                    </span>
                    {/* Círculo azul si está no leída */}
                    {!seenNotifications.includes(evento.id) && (
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "15px",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#007bff",
                          borderRadius: "50%",
                        }}
                      ></div>
                    )}
                    {/* Icono de tres puntos */}
                    <i
                      className="bi bi-three-dots"
                      style={{
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginTop: "-15px",
                      }}
                      onClick={() => handleMenuToggle(evento.id)}
                    ></i>

                    {/* Menú desplegable */}
                    {menuOpen === evento.id && (
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "25px",
                          backgroundColor: "#fff",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                          zIndex: 1001,
                          width: "160px",
                        }}
                      >
                        <button
                          onClick={() => handleMarkAsRead(evento.id)}
                          onMouseEnter={() => setHoveredButton("markAsRead")}
                          onMouseLeave={() => setHoveredButton(null)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            backgroundColor:
                              hoveredButton === "markAsRead"
                                ? "#f0f0f0"
                                : "transparent",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "#333",
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i
                            className="bi bi-check2"
                            style={{
                              marginRight: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              color:
                                hoveredButton === "markAsRead"
                                  ? "#000"
                                  : "#333",
                            }}
                          ></i>
                          Marcar como leído
                        </button>

                        <button
                          onClick={() => handleDelete(evento.id, "evento")}
                          onMouseEnter={() => setHoveredButton("delete")}
                          onMouseLeave={() => setHoveredButton(null)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            backgroundColor:
                              hoveredButton === "delete"
                                ? "#f0f0f0"
                                : "transparent",
                            border: "none",
                            textAlign: "left",
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "#d9534f",
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{
                              marginRight: "5px",
                              fontSize: "16px",
                              color:
                                hoveredButton === "delete"
                                  ? "#b52b27"
                                  : "#d9534f",
                            }}
                          ></i>
                          Eliminar
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </>
            )}

            {/* Sin notificaciones en No leídas */}
            {filter === "No leídas" &&
              filteredProximasAVencer.length === 0 &&
              filteredProximosEventos.length === 0 && (
                <li
                  style={{
                    padding: "20px 0",
                    textAlign: "center",
                    color: "#888",
                  }}
                >
                  <img
                    src="/img/no.svg"
                    alt="No tienes notificaciones"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                    loading="lazy"
                  />
                  <p>No tienes notificaciones.</p>
                </li>
              )}

            {/* Sin notificaciones en Todas */}
            {filter === "Todas" && totalNotifications === 0 && (
              <li
                style={{
                  padding: "10px 0",
                  textAlign: "center",
                  color: "#888",
                }}
              >
                No hay notificaciones.
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Notificaciones;